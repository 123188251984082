import React from 'react';
import { compose } from 'redux';
import { translate } from 'react-i18next';
import { PERMISSION } from '../../../helpers/constants';
import ButtonMasterSetting from '../ButtonMasterSetting';
import CustomNavLink from '../ButtonMasterSetting/navLink';
import { HeaderIconByPage, HeaderIconUrl } from '../HeaderIcon/index';
import { HEADER_SETTING_BY_ROLE } from '../../../helpers/constants';

const ErpMenu = ({ idRole, t , colorText, detailCheck = {} }) => {
  const headerIcon = HeaderIconByPage();
  const headerIconUrl = HeaderIconUrl();
  const headerByRole = HEADER_SETTING_BY_ROLE[idRole];

  // TODO: ネオマールのアイコンがある場合はこの条件を削除します
  return (
    <>
    {idRole && PERMISSION.NEOMARS.includes(idRole) ? (
      <>
        <div className="page_icon">
          <div className="page_name page_name--nowrap" style={{ color: colorText }}>
            {t('menu_partner.my_corp')}
          </div>
          <ButtonMasterSetting
            link="/master-setting"
            title="Master Setting"
            subTitle="集計表"
            color="dark-blue"
            icon="icon-list-alt"
          />
        </div>
        <div className="page_icon">
          <div className="page_name page_name--nowrap" style={{ color: colorText }}>
            {t('menu_partner.organization_master')}
          </div>
          <ButtonMasterSetting
            link="/organizations"
            subTitle=""
            title="ORGANIZATION MASTER"
            color="dark-blue"
            icon="fa-user-secret"
          />
        </div>
        <div className="page_icon">
          <div className="page_name page_name--nowrap" style={{ color: colorText }}>
            {t('menu_partner.user_master')}
          </div>
          <ButtonMasterSetting
            title="USER MASTER"
            subTitle="ユーザー"
            color="dark-blue"
            icon="icon-user"
            link="/users"
          />
        </div>
        <div className="page_icon" style={{ display: 'none' }}>
          <div className="page_name page_name--nowrap" style={{ color: colorText }}>
            {t('menu_partner.accounting')}
          </div>
          <ButtonMasterSetting
            link="/accounting"
            title="accounting"
            subTitle="accounting"
            color="dark-blue"
            icon="icon-list-alt"
          />
        </div>
        <div className="page_icon">
          <div className="page_name page_name--nowrap" style={{ color: colorText }}>
            {t('menu_partner.calendar_master')}
          </div>
          <ButtonMasterSetting
            link="/calendar_master"
            title="calendar_master"
            subTitle="calendar_master"
            color="dark-blue"
            icon="icon-list-alt"
          />
        </div>
      </>
    ) : (
      <div style={{ marginTop: '8px' }}>
        {headerByRole.map((page) => {
          if (page === 'dispatch' && (!PERMISSION.ERP_OPERATION_MANAGER.includes(idRole) || !detailCheck?.summarySetting)) {
            return null;
          }

          return (
            <div className="page_icon" key={page}>
              <CustomNavLink
                to={headerIconUrl[page]}
                text={t(`menu_partner.${page}`)}
                svg={headerIcon[page]}
              />
            </div>
          );
        })}
      </div>
    )}
  </>
  );
};

export default compose(translate('translations'))(ErpMenu);
