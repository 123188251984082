import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { Helmet } from 'react-helmet';
// import ButtonMaster from '../../../components/ButtonMaster';
// import HeaderMaintenance from '../../../components/HeaderMaintenance';
// import BreadcrumbMaintenance from '../../../components/BreadMaintenance';

import FooterMaintenance from '../../../components/FooterMaintenance';
import './style.scss';
import TableListMyPage from './TableList';
import TableAuthority from './TableAuthority';

import { shallowEqual, useSelector } from 'react-redux';
import BaseCheckAuthen from '../../../components/BaseCheckAuthen';
import ModalChangePass from './ModalChangePass';
import Header from '../../../components/Header';
import { translate } from 'react-i18next';

import CustomNavLink from 'components/HeaderMasterSetting/ButtonMasterSetting/navLink';
import { HeaderIconByPage, HeaderIconUrl } from 'components/HeaderMasterSetting/HeaderIcon/index';
import { ENUM_ROLE, HEADER_SETTING_BY_ROLE, ROLE, PERMISSION } from '../../../helpers/constants';

import { COLOR_ENDPOINT, MASTER_SETTING } from "../../../constants/endpoints";
import { getRole } from '../../../helpers/helpers';

const MyPage = ({t}) => {
  const [fontSize, setFontSizeAll] = useState('small');
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const userMeData = useSelector((store) => store.authentication.userMe, shallowEqual);
  const [modalChangePass, setModalChangePass] = useState(false);
  const [listMenu, setListMenu] = useState('');
  const [detailCheck, setDetailCheck] = useState('');

  const idRole = localStorage.getItem('role');
  const headerIcon = HeaderIconByPage();
  const headerIconUrl = HeaderIconUrl();
  const headerByRole = HEADER_SETTING_BY_ROLE[idRole];

  const toggle = () => {
    setModalChangePass(!modalChangePass);
  };

  const getListMenu = async () => {
    try {
      const data = await COLOR_ENDPOINT.GET_LIST_MENU_BY_ROLE({ role: idRole });
      if (data && data?.code === 0) {
        const newData = [5, 6, 7, '5', '6', '7'].includes(idRole)
          ? data?.data
          : data?.data?.filter((e) => e.name !== 'orderGroup');
          setListMenu(newData);
        }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getListMenu();
    getListOptionSetting();
  }, []);

  const getLinkCustomIcon = (role, itemName) => {
    switch (role) {
      case ROLE.PARTNER:
        return `partner_${itemName}`;
      case ROLE.CLIENT:
        return `client_${itemName}`;
      default:
        return itemName;
    }
  };

  const getListOptionSetting = async () => {
    const idProvider = localStorage.getItem('idProvide');
    try {
      const data = await MASTER_SETTING.GET_LIST_OPTION_SETTING(idProvider);
      if (data?.data) {
        setDetailCheck(data?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <BaseCheckAuthen>
      <div className="main">
        <Helmet>
          <title>マイページ</title>
        </Helmet>
        <Header />
        {/* TODO: ネオマーズのアイコンがある場合はこの条件を移動してください */}
        { !PERMISSION.NEOMARS.includes(idRole) &&
        <div style={{marginTop: '8px'}}>
          <div style={{display: 'flex', top: "8px", position: "relative", marginLeft: "23px"}}>
            { ![ENUM_ROLE.ERP_THREE, ENUM_ROLE.MANAGEMENT_GROUP_THREE].includes(idRole) ? (
              headerByRole.map((page) => {
                if (page === 'dispatch' && (!PERMISSION.ERP_OPERATION_MANAGER.includes(idRole) || !detailCheck?.summarySetting)) {
                  return null;
                }
                return (
                  <div key={page} className='page_icon' style={{marginRight: "32px", marginLeft: "0px"}}>
                    <CustomNavLink
                      to={headerIconUrl[page]}
                      text={t(`menu_partner.${page}`)}
                      svg={headerIcon[page]}
                    />
                  </div>
                );
              })
            ) : (
              listMenu?.length > 0 && listMenu.map((item, index) => (
                <div className='page_icon' style={{marginRight: "32px", marginLeft: "0px"}} key={item.name}>
                  <CustomNavLink
                    to={`/list?p=${item.name}`}
                    text={item.label}
                    svg={headerIcon[getLinkCustomIcon(getRole(idRole), item.name)]}
                    isActive={index === 0}
                  />
                </div>
              ))
            )}
          </div>
        </div>}

        {/* <HeaderMaintenance setFontSizeAll={setFontSizeAll}/> */}
        <ModalChangePass toggle={toggle} modal={modalChangePass}/>
        {/* TODO: ネオマーズのアイコンがある場合は、この余白を上へ移動してください */}
        <div className="body" style={{marginTop: PERMISSION.NEOMARS.includes(idRole) ? '65px' : '0px'}}>
          <div className="wrapper-main">
            <div className="pt-4">
              <div className="title">
                <label className={`font-weight-bold ${fontSize}`}>アカウント情報</label>
              </div>
              <div className="table-user-info" style={{ border: 'solid 1px black' }}>
                <div className="d-flex">
                  <div className="col-3" style={{ borderRight: 'solid 1px black' }}>
                    <label className={`${fontSize}`}>利用者ID</label>
                  </div>
                  <div className="col-9">
                    <label className={`${fontSize}`}>{userMeData?.data?.loginId}</label>
                  </div>
                </div>
                <div className="d-flex"
                     style={{ border: 'solid 1px black', borderLeft: 'none', borderRight: 'none' }}>
                  <div className="col-3" style={{ borderRight: 'solid 1px black' }}>
                    <label className={`${fontSize}`}>利用者名</label>
                  </div>
                  <div className="col-9">
                    <label className={`${fontSize}`}>
                      {userMeData?.data?.firstName + ' ' + userMeData?.data?.lastName}
                    </label>
                  </div>
                </div>

                <div
                  className="d-flex"
                  style={{ border: 'solid 1px black', borderLeft: 'none', borderRight: 'none', borderTop: 'none' }}
                >
                  <div className="col-3" style={{ borderRight: 'solid 1px black' }}>
                    <label className={`${fontSize}`}>フリガナ</label>
                  </div>
                  <div className="col-9">
                    <label className={`${fontSize}`}>
                      {' '}
                      {userMeData?.data?.firstNameKana + ' ' + userMeData?.data?.lastNameKana}
                    </label>
                  </div>
                </div>

                <div
                  className="d-flex"
                  style={{ border: 'solid 1px black', borderLeft: 'none', borderRight: 'none', borderTop: 'none' }}
                >
                  <div className="col-3" style={{ borderRight: 'solid 1px black' }}>
                    <label className={`${fontSize}`}>ユーザー連絡先</label>
                  </div>
                  <div className="col-9">
                    <label className={`${fontSize}`}>{userMeData?.data?.phoneNumber}</label>
                  </div>
                </div>

                <div className="d-flex">
                  <div className="col-3" style={{ borderRight: 'solid 1px black' }}>
                    <label className={`${fontSize}`}>ユーザーemail</label>
                  </div>
                  <div className="col-9">
                    <label className={`${fontSize}`}>{userMeData?.data?.email}</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center my-3 group-btn">
              <button className={`btn button-submit mx-3 ${fontSize}`} onClick={() => toggle()}>パスワード変更</button>
            </div>
            <FooterMaintenance/>
          </div>
        </div>
        </div>
    </BaseCheckAuthen>
  );
};

export default compose(translate('translations'))(MyPage);
