import React, { useState, useEffect } from 'react';
import '../style.scss';
import { VERSION_SETTING_ENDPOINT, COLOR_ENDPOINT } from 'constants/endpoints';
import ButtonChecked from './ButtonChecked';
import { pushToast } from 'components/Toast';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Link, useHistory } from 'react-router-dom';
import FooterMaintenance from 'components/FooterMaintenance';
import ModalConfirm from 'components/ModalConfirm';
import MemoHistory from '../../../../components/MemoHistory';
import { SCREEN_NAME } from '../../../../helpers/constants';
import { genId } from 'helpers/helpers';
import _ from 'lodash';
import Loading from 'components/Loading/Loading';
import { getDataHistoryMasterSetting } from 'actions/authentication';
import { useDispatch } from 'react-redux';

const reorder = (list, startIndex, endIndex, state) => {
  const result = Array.from(list?.[state]);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const TableWorkerMenuSetting = ({ colorScreen, statusBeforeConfirm }) => {
  const dispatch = useDispatch();
  let serviceProductId = process.browser && new URL(window.location.href).searchParams.get('serviceProductId');
  let serviceName = decodeURIComponent(new URL(window.location.href).searchParams.get('serviceName')) || '';
  let productName = decodeURIComponent(new URL(window.location.href).searchParams.get('productName')) || '';
  let version = decodeURIComponent(new URL(window.location.href).searchParams.get('version')) || '';
  const serviceId = serviceProductId?.split('P')?.[0];
  const productId = `P${serviceProductId?.split('P')?.[1]}`;
  let id = process.browser && new URL(window.location.href).searchParams.get('sortKey');
  const [dataMenu, setDataMenu] = useState();
  const [color, setColor] = useState('');
  const [idRole, setIdRole] = useState('');
  const [itemDelete, setItemDelete] = useState({});
  const [modalConfirm, setModalConfirm] = useState(false);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [option, setOption] = useState([]);

  const toggle = (item) => {
    setModalConfirm(!modalConfirm);
    setItemDelete(item);
  };

  const MenuByService = async ({ id = '' }) => {
    setLoading(true);
    try {
      const data = await VERSION_SETTING_ENDPOINT.MENU_BY_SERVICE({ id, serviceProductId});
      if (data) {
        const beforeConfirm = data?.data?.menuSettings?.beforeConfirm?.map((item) => {item.id = genId(); item.state = 'beforeConfirm'; return item});
        const workOnTheDay = data?.data?.menuSettings?.workOnTheDay?.map((item) => {item.id = genId(); item.state = 'workOnTheDay'; return item});
        const newData = {beforeConfirm,  workOnTheDay}
        setDataMenu(newData);
        setOption(['', ...(data?.data?.option || [])]);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const getColor = async ({ role = '' }) => {
    try {
      const data = await COLOR_ENDPOINT.GET_COLOR({ role: idRole });
      if (data && data?.code === 0) {
        setColor(data?.data?.code);
        localStorage.setItem('color', data?.data?.code);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const newRole = localStorage.getItem('role');
    setIdRole(newRole);
  }, []);

  useEffect(() => {
    if (idRole) {
      getColor({ role: idRole });
    }
  }, [idRole]);

  const onDragEnd = (result, state) => {
    if (!result.destination) {
      return;
    }
    const newData = reorder(dataMenu, result.source.index, result.destination.index, state);
    setDataMenu({...dataMenu, [state]: newData});
  };

  useEffect(() => {
    MenuByService({ id: id });
  }, [id]);

  const createUpdate = async (body) => {
    setLoading(true);
    try {
      const data = await VERSION_SETTING_ENDPOINT.CREATE_UPDATE(body);
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
        setLoading(false);
      } else {
        pushToast('success', 'Create data success');
        dispatch(getDataHistoryMasterSetting({ logId: '', screenName: SCREEN_NAME.WORKER_SETTINGS }));
        MenuByService({ id });
        setLoading(false);
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
      setLoading(false);
    }
  };

  const handleConfirm = () => {
    deleteVersion();
  };

  const deleteVersion = async () => {
    try {
      const data = await VERSION_SETTING_ENDPOINT.DELETE_VERSION({
        versionServiceId: itemDelete.versionServiceId,
        menuSettingId: itemDelete.menuSettingId,
        serviceProductId, menuName: itemDelete.state
      });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        toggle();
        pushToast('success', 'Delete user success');
        MenuByService({ id: id });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onClickDeleteVersion = (item, state) => {
    if (!item.hasOwnProperty('versionServiceId')) {
      const newData = {...dataMenu, [state]: sortElm(dataMenu?.[state]?.filter(elm => elm.id !== item.id))};
      setDataMenu(newData);
    } else {
      toggle(item);
    }
  };

  const onClickSubmit = () => {
    const beforeConfirm = dataMenu?.beforeConfirm.map((item) => _.pick(item, ['name', 'isUsed', 'sortNum', 'menuSettingId', 'isDefault']));
    const workOnTheDay = dataMenu?.workOnTheDay.map((item) => _.pick(item, ['name', 'isUsed', 'sortNum', 'menuSettingId', 'isDefault']));
    const newData = {beforeConfirm, workOnTheDay};
    const updateTitle = `編集を保存 ${productName} ${serviceName}`
    createUpdate({ menuSettings: newData, screenName : SCREEN_NAME.WORKER_SETTINGS , serviceProductId: serviceProductId, versionServiceId: id, updateTitle });
  };

  const addNew = (state) => {
    const maxElm = (_.maxBy(dataMenu?.[state], 'sortNum')?.sortNum || 0) + 1;
    const newData = {...dataMenu, [state]: [...dataMenu?.[state], { isUsed: false, name: '', sortNum: maxElm, id: genId()}]};
    setDataMenu(newData);
  };

  const sortElm = ((data) =>
    data?.map((item, index) => {
      item.sortNum = index;
      return item;
    }))

  const onChangeChecked = ({ status = false, index, state }) => {
    const newData = dataMenu?.[state]?.map(item => {
      if (item.id === index) {
        return { ...item, isUsed: status };
      }
      return item;
    });
    setDataMenu({...dataMenu, [state]: newData});
  };

  const handleChangeText = (e, it, state) => {
    if(it.isDefault) return false
    const valueInput = e.target.value;
    const newData = dataMenu?.[state]?.map((item, i) => {
      if (item.id === it.id) {
        return { ...item, name: valueInput };
      }
      return item;
    });
    setDataMenu({...dataMenu, [state]: newData});
  };

  const renderItem = (item, index, state) => {
    return (
      <Draggable key={`${index + 1}`} draggableId={`${index + 1}`} index={index}>
        {(provided, snapshot) => (
          <tr
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className="item-worker"
            key={index}
            >
            <td>
            </td>
            <td>
              {
                !item.isDefault && (
                  <div
                    className="icon"
                    style={{ borderColor: '#555555', color: '#555555' }}
                    onClick={() => onClickDeleteVersion(item, state)}
                  >
                    <i className="fas fa-times"></i>
                  </div>
                )
              }
            </td>
            <td className="text-center">
              {
                state === 'beforeConfirm' ? (
                  <select
                    name="name"
                    className="text-input select--w80"
                    id="name_beforeConfirm"
                    style={{ borderColor: "#555555", color: "#555555", background: "#ffffff" }}
                    value={item.name}
                    onChange={(e) => handleChangeText(e, item, state)}
                  >
                    {
                      option.map((i, ind) => (
                        <option value={i} key={ind}>
                          {i}
                        </option>
                      ))
                    }
                  </select>
                ) : (
                  <input
                    type="text"
                    className="text-input"
                    value={item.name}
                    disabled={item.isDefault}
                    style={{ borderColor: '#555555', color: '#555555' }}
                    onChange={(e) => handleChangeText(e, item, state)}
                  />
                )
              }
            </td>
            <td>
              <ButtonChecked color={color} status={item.isUsed} index={item.id} state={state} onChange={onChangeChecked} />
            </td>

            <td>
            <div className="item-button">
              {
                item.isDefault ? (
                  <button className='button--disabled' style={{ backgroundColor: `${color}` }} disabled={!item.hasOwnProperty('versionServiceId')} >編集</button>
                ) : (
                  <Link className="text-decoration-none"
                      to={`/configuration-worker/worker-item-settings?versionServiceId=${item.versionServiceId}&menuSettingId=${item.menuSettingId}&serviceProductId=${serviceProductId}&menuName=${state}&productName=${productName}&serviceName=${serviceName}&version=${version}`}>
                    <button
                        style={{ backgroundColor: `${color}` }}
                        disabled={!item.hasOwnProperty('versionServiceId')}
                    >編集
                    </button>
                  </Link>
                )
              }
            </div>
          </td>
          </tr>
        )}
      </Draggable>
    );
  };

  const onReturn = () => {
    history.push(`/configuration-worker/version-service?productId=${productId}&serviceId=${serviceId}&productName=${productName}&serviceName=${serviceName}`);
  }

  return (
    <div className="wrapper-master-setting">
      <Loading loading={loading} />
      <div className="body pb-1">
      <button className="mypage_ab_custom" onClick={onReturn}>戻る</button>
        <div className="wrapper-main">
          <div className="wrapper-result">
            <div className="result-title text-center mb-4 mt-2">
              {`作業手順設定＞${productName || ''}＞${serviceName || ''}＞${version || ''}`}
            </div>
            <table className="form-worker">
              <thead>
                <tr>
                  <th className="text-center" scope="col"></th>
                  <th className="text-center" scope="col"></th>
                  <th className="text-center" scope="col">作業工程名</th>
                  <th className="text-center" scope="col">表示</th>
                  <th className="text-center" scope="col">作業工程手順</th>
                </tr>
              </thead>
              <DragDropContext onDragEnd={e => onDragEnd(e, 'beforeConfirm')}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <tbody {...provided.droppableProps} ref={provided.innerRef}>
                      <tr className="item-worker">
                        <td>事前確認</td>
                      </tr>
                      {dataMenu &&
                        dataMenu?.beforeConfirm?.map((listItem, index) => {
                          return renderItem(listItem, index, 'beforeConfirm');
                        })}
                      {
                        statusBeforeConfirm &&
                        <tr className="item-worker">
                          <td></td>
                          <td>
                            <div
                              className="icon"
                              style={{ borderColor: '#555555', color: '#555555' }}
                              onClick={() => addNew('beforeConfirm')}
                            >
                              <i className="fas fa-plus"></i>
                            </div>
                          </td>
                        </tr>
                      }

                      {provided.placeholder}
                    </tbody>
                  )}
                </Droppable>
              </DragDropContext>
              <DragDropContext onDragEnd={e => onDragEnd(e, 'workOnTheDay')}>
                <Droppable droppableId="droppable1">
                  {(provided, snapshot) => (
                    <tbody {...provided.droppableProps} ref={provided.innerRef}>
                      <tr className="item-worker">
                        <td>当日作業</td>
                      </tr>
                      {dataMenu &&
                        dataMenu?.workOnTheDay?.map((listItem, index) => {
                          return renderItem(listItem, index, 'workOnTheDay');
                        })}
                      <tr className="item-worker">
                        <td></td>
                        <td>
                          <div
                            className="icon"
                            style={{ borderColor: '#555555', color: '#555555' }}
                            onClick={() => addNew('workOnTheDay')}
                          >
                            <i className="fas fa-plus"></i>
                          </div>
                        </td>
                      </tr>
                      {provided.placeholder}
                    </tbody>
                  )}
                </Droppable>
              </DragDropContext>
            </table>
          </div>
          <div className="d-flex justify-content-end align-items-center group-button">
            <button style={{ backgroundColor: `${color}` }} onClick={() => onClickSubmit()}>
              編集を保存
            </button>
          </div>
        </div>
      </div>
      <MemoHistory colors={colorScreen?.code} colorText={colorScreen?.colorText} screenName={SCREEN_NAME.WORKER_SETTINGS} />
      <FooterMaintenance />
      <ModalConfirm toggle={toggle} modal={modalConfirm} handleConfirm={handleConfirm} />
    </div>
  );
};

export default TableWorkerMenuSetting;
