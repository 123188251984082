import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './style.scss';
import { ROLE, HEADER_ROLE_ENUM } from 'helpers/constants';
import { getRole } from '../../helpers/helpers';

const HeaderContainer = ({
  totalConfirm,
  idRole,
  userMe,
  getRoleMe,
  url,
  role,
  onClickLogOut,
  returnUrl,
  isUserRole2,
  isChat,
  rootPath,
  logo,
}) => {
  const headerIcon = () => {
    return `
      <svg width="190" height="26" viewBox="0 0 190 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M40.9268 24.8616V20.7451C43.208 21.9038 45.1591 22.4832 46.78 22.4832C48.6711 22.4832 49.6116 21.7005 49.6116 20.1352C49.6116 19.129 48.8312 18.2447 47.2803 17.4824L45.7194 16.7201C44.0285 15.8866 42.8178 14.9922 42.0874 14.0266C41.357 13.0711 40.9968 11.8819 40.9968 10.4589C40.9968 8.48708 41.6171 6.95229 42.8478 5.86472C44.0785 4.76699 45.8095 4.22829 48.0307 4.22829C49.4215 4.22829 51.0724 4.47223 52.9935 4.97027V8.91397C51.1425 8.13133 49.6116 7.73493 48.4009 7.73493C46.4899 7.73493 45.5393 8.45658 45.5393 9.8999C45.5393 10.8452 46.2497 11.6481 47.6605 12.3088L49.0013 12.939C51.0024 13.8639 52.4032 14.799 53.1836 15.7443C53.964 16.6896 54.3643 17.9093 54.3643 19.4034C54.3643 21.3651 53.6939 22.9507 52.3631 24.1704C51.0324 25.3901 49.2914 26 47.1602 26C45.029 26 43.0279 25.6239 40.9368 24.8616"
        fill="#489CFF"
      />
      <path
        d="M66.6211 25.5019C65.4905 25.8373 64.6 26 63.9597 26C59.8674 26 57.8162 24.0586 57.8162 20.1657V4.24861H62.5389V7.79591H66.291V10.7943H62.5389V19.5355C62.5389 21.7208 63.5494 23.7943 65.1703 24.4753C65.5806 24.6481 66.2309 24.8006 66.6211 24.7599V25.5121V25.5019Z"
        fill="#489CFF"
      />
      <path d="M92.2654 0H88.7935V25.5934H92.2654V0Z" fill="#489CFF" />
      <path d="M99.0392 0H95.5673V25.5934H99.0392V0Z" fill="#489CFF" />
      <path
        d="M137.321 25.4002C135.84 25.7966 134.479 25.9999 133.218 25.9999C131.107 25.9999 129.446 25.3697 128.215 24.1094C126.995 22.849 126.384 21.1414 126.384 18.9663C126.384 16.7912 127.015 15.0633 128.276 13.8232C129.536 12.5832 131.277 11.9632 133.498 11.9632C134.569 11.9632 135.81 12.136 137.221 12.4917V15.1039C135.76 14.6262 134.589 14.3823 133.719 14.3823C132.648 14.3823 131.797 14.799 131.147 15.6325C130.497 16.4659 130.177 17.5637 130.177 18.946C130.177 20.3283 130.527 21.487 131.227 22.3307C131.928 23.1743 132.868 23.6012 134.039 23.6012C135.109 23.6012 136.2 23.3674 137.331 22.8897V25.3901L137.321 25.4002Z"
        fill="#071945"
      />
      <path d="M143.924 6.40341H140.362V25.7052H143.924V6.40341Z" fill="#071945" />
      <path
        d="M153.49 26C151.428 26 149.788 25.3698 148.577 24.0993C147.366 22.8287 146.756 21.1313 146.756 18.9867C146.756 16.842 147.366 15.104 148.587 13.8538C149.808 12.6036 151.468 11.9734 153.57 11.9734C155.671 11.9734 157.342 12.6036 158.572 13.8538C159.793 15.104 160.403 16.8116 160.403 18.9664C160.403 21.1212 159.793 22.8897 158.562 24.1298C157.332 25.38 155.641 26 153.48 26M153.55 23.7435C155.591 23.7435 156.621 22.1477 156.621 18.9664C156.621 17.5129 156.351 16.3542 155.811 15.5105C155.271 14.6669 154.52 14.24 153.58 14.24C152.639 14.24 151.899 14.6669 151.358 15.5105C150.818 16.3542 150.548 17.523 150.548 18.9969C150.548 20.4707 150.818 21.609 151.358 22.473C151.899 23.3268 152.629 23.7537 153.56 23.7537"
        fill="#071945"
      />
      <path
        d="M171.009 25.6951V23.1642C169.859 25.0547 168.358 26 166.507 26C165.326 26 164.396 25.6239 163.715 24.8616C163.035 24.0993 162.695 23.0727 162.695 21.7514V12.2682H166.257V20.8569C166.257 22.3815 167.037 22.9406 167.758 23.1439C168.888 23.459 170.109 23.3776 171.009 22.3917V12.2682H174.571V25.6849H171.009V25.6951Z"
        fill="#071945"
      />
      <path
        d="M186.438 25.695V23.1641C185.528 25.0547 184.087 25.9999 182.116 25.9999C180.525 25.9999 179.284 25.4104 178.374 24.2314C177.473 23.0523 177.013 21.4261 177.013 19.3526C177.013 17.1063 177.523 15.3072 178.544 13.9757C179.564 12.6341 180.945 11.9734 182.666 11.9734C184.047 11.9734 185.297 12.5324 186.428 13.6403V6.40341H190V25.7052H186.428L186.438 25.695ZM186.438 15.7849C185.578 14.8092 184.667 14.3213 183.686 14.3213C182.816 14.3213 182.116 14.7482 181.595 15.602C181.075 16.4558 180.815 17.6043 180.815 19.0477C180.815 21.792 182.416 23.9061 183.636 23.9061C185.057 23.9061 185.648 23.2048 186.448 22.2087V15.7849H186.438Z"
        fill="#071945"
      />
      <path
        d="M73.495 14.8092C73.635 11.8514 74.9458 10.3878 76.8768 10.3878C78.8079 10.3878 79.7685 11.9023 79.7685 14.9414H76.0364V17.645H84.411C84.411 14.0876 83.8207 11.4855 82.6401 9.84911C81.4594 8.20251 79.5884 7.37921 77.0269 7.37921C74.4655 7.37921 72.4444 8.22284 70.8935 9.89993C69.3426 11.577 68.5722 13.803 68.5722 16.5575C68.5722 19.4746 69.4227 21.7717 71.1136 23.4589C72.8046 25.1462 75.1159 25.9898 78.0375 25.9898C80.0486 25.9898 81.9997 25.6849 84.251 25.0141V21.731C82.2098 22.5035 80.4388 23.0422 78.1075 22.7576C74.7757 22.3612 73.8852 21.0297 73.565 17.6349C73.565 17.6349 73.485 15.5004 73.525 14.7889"
        fill="#489CFF"
      />
      <path
        d="M119.361 22.8592C119.1 22.8999 118.9 22.9202 118.74 22.9202C117.86 22.9202 117.419 22.2189 117.419 20.8264V12.9491C117.419 9.23922 115.128 7.38934 110.546 7.38934C108.444 7.38934 106.333 7.79591 104.192 8.61921V11.9226C106.073 10.8655 107.934 10.337 109.775 10.337C111.846 10.337 112.887 11.2619 112.887 13.1118V14.738H112.857V17.4925H112.887V22.6051C111.896 23.4894 110.626 23.3776 109.535 23.2455C109.145 23.1946 108.404 22.8592 107.894 22.2595C107.484 21.7818 107.194 21.0703 107.194 20.4097C107.194 18.6716 108.414 17.7263 110.836 17.5332V14.7584C108.334 14.8499 106.383 15.3682 104.983 16.3338C103.432 17.4011 102.661 18.9155 102.661 20.8772C102.661 22.3917 103.142 23.6215 104.092 24.577C105.053 25.5324 106.293 26 107.824 26C109.625 26 111.306 25.2275 112.877 23.6825H113.087C113.637 25.2275 114.888 26 116.819 26C117.58 26 118.47 25.8475 119.471 25.5426L119.361 22.8491V22.8592Z"
        fill="#489CFF"
      />
      <path
        d="M2.14512 12.4105C3.716 12.939 5.67709 12.0445 6.13735 10.3675C6.60761 8.69036 5.80716 6.87097 4.12623 6.31194C2.55535 5.7834 0.594254 6.67785 0.133997 8.35494C-0.336265 10.032 0.454176 11.8514 2.14512 12.4105Z"
        fill="#071945"
      />
      <path
        d="M5.00668 14.0368C5.9372 14.0164 6.77767 14.6466 6.98779 15.5614C7.38801 17.391 8.15844 19.4848 9.41914 19.7389C11.4803 20.1556 12.9611 17.8585 14.0617 16.8319C14.1718 16.7303 14.3419 16.7303 14.452 16.8319L16.273 18.7123C16.383 18.8241 16.383 19.0172 16.273 19.129C13.2013 22.0462 11.4903 24.0383 8.11842 23.154C5.60702 22.4934 3.83603 19.5458 3.15565 16.4254C2.8855 15.2057 3.796 14.0469 5.02669 14.0164"
        fill="#489CFF"
      />
      <path
        d="M29.4004 21.2228C28.67 21.0398 27.8195 21.5074 27.6594 22.2799C27.4993 23.0523 27.9195 23.8451 28.7 24.0484C29.4304 24.2314 30.2809 23.7638 30.4409 22.9913C30.591 22.2189 30.1808 21.4261 29.4004 21.2228Z"
        fill="#489CFF"
      />
      <path
        d="M27.4993 18.7834C26.5788 18.8749 25.6883 18.3057 25.4181 17.4011C24.8778 15.602 23.9673 14.1282 22.6966 13.9656C20.6054 13.7013 19.3948 15.8663 18.3742 16.9742C18.2741 17.086 18.104 17.0961 17.994 16.9945L16.0429 15.1344C15.9228 15.0226 15.9128 14.8397 16.0229 14.7177C18.8745 11.577 20.3453 9.81858 23.7672 10.4488C26.3186 10.9163 28.2897 13.1728 29.2002 16.2322C29.5604 17.4316 28.74 18.6513 27.5093 18.7732"
        fill="#489CFF"
      />
    </svg>
    `;
  };

  const userIdRole = idRole || localStorage.getItem('role');
  const userRole = getRole({idRole : userIdRole});
  const [isExpanded, setIsExpanded] = React.useState(false);
  const dropdownRef = useRef(null);

  const [isLogoError, setIsLogoError] = useState(false);

  const handleImageError = () => {
    setIsLogoError(true);
  };

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsExpanded(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="header-container">
      {!logo || isLogoError ? (
        <div dangerouslySetInnerHTML={{ __html: headerIcon() }} onClick={rootPath} className="default-img" />
      ) : (
        <div className="cursor-pointer" onClick={rootPath}>
          <img id="img-icon" src={logo} onError={handleImageError} />
        </div>
      )}

      <div className="header-right-section">
        {url !== '/select-role' && (
          <>
            {!['1', 1, '14', 14].includes(userIdRole) && isUserRole2 && (
              <div className="approval-request-container" onClick={returnUrl}>
                <div className="approval-request-text">承認依頼</div>
                <div className="approval-request-badge">{totalConfirm || 0}</div>
              </div>
            )}
          </>
        )}

        {url !== '/select-role' && HEADER_ROLE_ENUM[userIdRole] && (
          <Link to="/select-role" className="role-button role-button-link" onClick={() => getRoleMe()}>
            {HEADER_ROLE_ENUM[userIdRole]}
          </Link>
        )}

        <div className="dropdown-container" ref={dropdownRef}>
          <div className="dropdown-header" onClick={handleToggle}>
            <span className="dropdown-text">
              {userMe && userMe.data && userMe?.data?.firstName + ' ' + userMe?.data?.lastName}様
            </span>
            <span
              className={`fas ${isExpanded ? 'fa-chevron-up' : 'fa-chevron-down'}`}
              style={{ width: '10px', height: '6px', display: 'flex', alignItems: 'center' }}
            ></span>
          </div>

          {isExpanded && (
            <div className="dropdown-menu">
              <div className="dropdown-item">
                <p className="dropdown-item-title">
                  {userMe && userMe.data && userMe?.data?.firstName + ' ' + userMe?.data?.lastName}様
                </p>
                <p className="dropdown-item-subtitle">{userMe.data.email}</p>
              </div>

              <Link to="/select-role" className="dropdown-item" onClick={() => getRoleMe()}>
                権限切替
              </Link>

               {url !== '/select-role' && (userRole === ROLE.CENTER || userRole === ROLE.PARTNERGROUP) && (
                <Link to="/my_page" className="dropdown-item">
                  マイページ
                </Link>
              )}

              {url !== '/select-role' && (
                 <Link to="/chat-page" className="dropdown-item" target="_blank" rel="noopener noreferrer">
                  チャット
                </Link>
              )}

              <Link onClick={() => onClickLogOut()} className="dropdown-item">
                ログアウト
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HeaderContainer;
